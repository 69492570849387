<template>
    <div>
      <platformUser></platformUser>
    </div>
</template>
<script>
import platformUser from '@/views/system/platform/user/index.vue'
export default {
  name: 'routerUser',
  components: {
    platformUser
  },
  created () {
  },
  methods: {
  },
  data () {
    return {
    }
  }
}
</script>
<style>
</style>
